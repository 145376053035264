import React, { useState } from "react";
import "../../../components/PageBlocks/Home/PopularProduct/PopularProduct.scss";
import ProductItem from "../../CommonBlocks/ProductItem/ProductItem";

import { withTranslation } from "../../../i18n";

const ProductsWithTitle = ({ list, t, title, WithoutT = false }) => {
    const [show_more, setShowMore] = useState(false);

    if (list.length === 0) return null;

    return (
        <div className="popular_product sale home_page_product">
            <p className="title">{WithoutT ? title : t(title)}</p>
            <div className={`product_block ${show_more ? "opened" : "less"}`}>
                {list && list.length > 0 ? (
                    list.slice(0, show_more ? undefined : 5).map((el, idx) => <ProductItem key={idx} productItem={el} index={idx} />)
                ) : (
                    <div>No items found</div>
                )}
            </div>
            {list && list.length > 5 && (
                <button onClick={() => setShowMore(true)} className={`show_more ${show_more ? "invisible" : null}`}>
                    {t("смотреть ещё")}
                </button>
            )}
        </div>
    );
};

export default withTranslation("product")(ProductsWithTitle);
