import React, { useEffect, useState } from "react";
import "./VisitedProduct.scss";
import ProductItem from "../../../CommonBlocks/ProductItem/ProductItem";
import Slider from "react-slick";
/* import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; */
import { API_BASE_URL } from "../../../../context/appState";
import { sendRequest, slideHeightFix } from "../../../HelperComponents/functions";
import { withTranslation } from "../../../../i18n";
import { useRouter } from "next/router";

const VisitedProduct = ({ t, initialProduct, noMargin = false }) => {
    const settings = {
        afterChange: () => slideHeightFix(),
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 860,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3.7,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 3.1,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [visited, setVisited] = useState([]);

    const [loading, setLoading] = useState(true);

    const router = useRouter();

    useEffect(() => {
        visitedCheck(typeof localStorage === "undefined" ? [] : JSON.parse(localStorage.getItem("visited")));

    }, [router]);

    useEffect(() => {
        slideHeightFix();
    }, [loading]);

    const visitedCheck = async cart => {
        let tempCart = initialProduct ? cart && cart.length > 0 ? [initialProduct, ...cart] : [initialProduct] : cart;
        let changedList = [];
        let changedVisList = [];
        let cartIds = tempCart && tempCart.length > 0 && tempCart.map(el => el.id);
        if (cartIds && cartIds.length > 0) {
            await sendRequest(`${API_BASE_URL}/check/products/?product_ids=${cartIds.reverse().join(",")}`, "GET")
                .then(response => {
                    response.status === 200
                        ? response.json().then(data => {
                            data.forEach((element, idx) => {
                                if (idx <= 19) {
                                    let analog = tempCart.find(searchedElement => searchedElement.id === element.id);
                                    changedVisList.push({ ...analog, ...element });
                                    if (router.query.slug) {
                                        if (router.query.slug[0] !== analog.slug) {
                                            changedList.push({ ...analog, ...element });
                                        }
                                    } else {
                                        changedList.push({ ...analog, ...element });
                                    }
                                    if (data.length - 1 <= 19 ? idx === data.length - 1 : idx === 19) {
                                        setVisited(changedList);
                                        // console.log(changedList)
                                        localStorage.setItem("visited", JSON.stringify(changedVisList.reverse()));
                                        if (changedList.length > 0) setLoading(false);
                                    }
                                }
                            });
                        })
                        : response.json().then(data => {
                        });
                })
                .catch(error => console.log(error));
        }
    };

    return (
        !loading && (
            <div className={`visited_product with_slider home_page_product_slider${noMargin ? ' noMargin' : ''}`}>
                <p className="title">{t("Просмотренные товары")}</p>
                <div className="products_slider">
                    {visited.length <= 4 ? (
                        <div className="no_slider">
                            {visited.map((el, idx) => (
                                <ProductItem key={idx} productItem={el} index={idx} isVisited={true} />
                            ))}
                        </div>
                    ) : (
                        <Slider {...settings}>
                            {visited.map((el, idx) => (
                                <div key={idx}>
                                    <ProductItem productItem={el} index={idx} moreThanFour={true} isVisited={true} />
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>
            </div>
        )
    );
};

export default withTranslation("home")(VisitedProduct);
